<template>
  <div>
    <a-spin v-if="!adData" class="d-flex justify-content-center align-items-center ">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
    </a-spin>
    <div v-else class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br />
        <br />
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Ad</strong>
            </div>
            <div class="text-muted">Edit search XML ad information</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Ad's name.</span>
                      </template>
                      <a-input type="text" placeholder="Ad Name" v-decorator="['name', {
                        initialValue: adData.Name,
                        rules: [
                          { required: true, message: 'Name is required.' },
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Default CPC">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Default cost per click.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Default CPC" @change="updateDefaultCPC"
                        v-decorator="['defaultCPC', { initialValue: Math.round((adData.DefaultCPC + Number.EPSILON) * 10000) / 10000, rules: [{ validator: defaultCpcValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of clicks allowed per day. The system will even out distribution of clicks
                          throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                        v-decorator="['ClicksDailyLimit', { initialValue: Math.round((adData.ClicksDailyLimit + Number.EPSILON) * 10000) / 10000 }]" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Title">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Title of your ad. Click on the information icon to the right to see the list of macros
                          that can be put inside the text.</span>
                      </template>
                      <a-input placeholder="Ad Title" v-decorator="['title', {
                        initialValue: adData.Title,
                        rules: [
                          { required: true, message: 'Title is required.' },
                          { max: 256, message: 'Maximum 256 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                          <template slot="title">
                            <b>{query}</b> - Lowercase query string will be replaced.<br>
                            <b>{Query}</b> - Capitalized query string will be replaced.<br>
                            <b>{QUERY}</b> - Uppercase query string will be replaced.<br>
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Description that appears in your ad. Click on the information icon to the right to see the
                          list of macros that can be put inside the text.</span>
                      </template>
                      <a-input placeholder="Description" v-decorator="['description', {
                        initialValue: adData.Description,
                        rules: [
                          { required: true, message: 'Description is required.' },
                          { max: 256, message: 'Maximum 256 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                          <template slot="title">
                            <b>{query}</b> - Lowercase query string will be replaced.<br>
                            <b>{Query}</b> - Capitalized query string will be replaced.<br>
                            <b>{QUERY}</b> - Uppercase query string will be replaced.<br>
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description 2">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Description that appears below first description in your ad. Click on the information icon
                          to the right to see the list of macros that can be put inside the text.</span>
                      </template>
                      <a-input placeholder="Additional Description" v-decorator="['description2', {
                        initialValue: adData.Description2,
                        rules: [
                          { max: 256, message: 'Maximum 256 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                          <template slot="title">
                            <b>{query}</b> - Lowercase query string will be replaced.<br>
                            <b>{Query}</b> - Capitalized query string will be replaced.<br>
                            <b>{QUERY}</b> - Uppercase query string will be replaced.<br>
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domain/Brand">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain or brand name for your ad.</span>
                      </template>
                      <a-input placeholder="Ad Domain/Brand" v-decorator="['AdDomainBrand', {
                        initialValue: adData.AdDomainBrand,
                        rules: [
                          { max: 256, message: 'Maximum 256 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Destination URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Destination URL that loads when user clicks your ad. Click on the information icon to the
                          right to see the list of macros that can be put inside the URL.</span>
                      </template>
                      <a-input type="url" placeholder="Destination URL" v-decorator="['destinationURL', {
                        initialValue: adData.DestinationUrl,
                        rules: [
                          { required: true, message: 'Destination URL is required.' },
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                          <template slot="title">
                            <b>{query}</b> - Lowercase query string will be replaced.<br>
                            <b>{Query}</b> - Capitalized query string will be replaced.<br>
                            <b>{QUERY}</b> - Uppercase query string will be replaced.<br>
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Remote Channel">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Channel name for ad on the advertiser side.</span>
                      </template>
                      <a-input placeholder="Remote Channel" v-decorator="['RemoteChannel', {
                        initialValue: adData.RemoteChannel,
                        rules: [
                          { max: 8, message: 'Maximum 8 characters allowed.' }
                        ]
                      }]" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Feeds">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Publisher feeds to connect to the ad. This will make the ad appear in Publisher feed XML
                          response if all the targeting is matched.</span>
                      </template>
                      <a-select
                        v-decorator="['pubfeeds', { initialValue: (adData.Pubfeeds === '' ? [] : adData.Pubfeeds) }]"
                        placeholder="Please select Feeds" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in pubfeed.list" :key="obj.Id">[{{ obj.Id }}] {{  obj.Name  }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>

              <a-divider style="margin-top: 34px;">Targeting</a-divider>
              <a-tabs default-active-key="1" class="mb-4">
                <a-tab-pane key="1" tab="Countries">
                  <a-row>
                    <a-input-search placeholder="Filter Countries" style="width: 200px" class="mb-2"
                      v-model="filterSearch" />
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half"
                        v-model="displayEnabledCountries" /> Display Only Enabled</label>
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half" v-model="selectAllCountries"
                        @click="handleCountries" /> De/Select All</label>
                  </a-row>
                  <a-spin class="d-flex justify-content-center" v-if="!countriesList">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                  </a-spin>
                  <ul v-else class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li class="list-group-item treeContainer" v-for="(item, index) in enabledCountries" :key="index"
                      :style="item.Enabled ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" v-model="item.Enabled"
                          @click="enableCountry($event, item)" />
                        <span class="col-sm"><img :src="matchIcon(countries, item.CC)" /> {{  item.Country  }} ({{  item.CC
                          }})</span>
                      </label>
                      <span class="col-sm cpos-half">
                        <label class="mr-1 no-margin">Bid Adjustment:
                          <span class="valuePadding"><input type="number" min="0" max="100"
                              class="form-control bid-adjustment ml-1" @keyup="adjustBid($event, item, null)"
                              v-model="item.BidAdjustment">%</span>
                        </label>
                      </span>
                      <span class="col-sm cpos">
                        <label for="" class="mr-1">Calculated Bid: </label>
                        <span class="calculatedBid">{{
                           typeof item.CalculatedBid === 'undefined' ? '' : Math.round(item.CalculatedBid * 100000) /
                           100000

                          }}</span>
                      </span>
                    </li>
                  </ul>
                </a-tab-pane>
                <a-tab-pane v-if="adData.Os" key="2" tab="Operating Systems" force-render>
                  <treeList :listData="adData.Os" type="os" v-model="OSData" :defaultVal="DefaultCPC"></treeList>
                </a-tab-pane>
                <a-tab-pane v-if="adData.Browsers" class="" style="overflow-x: scroll; overflow-y:hidden" key="3"
                  tab="Browsers" force-render>
                  <treeList :listData="adData.Browsers" type="browser" v-model="BrowserData" :defaultVal="DefaultCPC">
                  </treeList>
                </a-tab-pane>
              </a-tabs>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                    :class="'btn btn-' + (adData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-0 ml-lg-2'"
                    @click.prevent="toggleAdStatus">
                    {{  adData.Status == 0 ? 'Deactivate' : 'Activate'  }} Ad
                  </button>
                  <button type="button" class="btn px-5 ml-0 ml-lg-2" @click="goBack">Cancel</button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { Modal } from 'ant-design-vue'
import treeList from '@/components/custom/TreeList'
import { getAds, changeAdStatus } from '@/api/xml/ad'
import staticCountryData from '@/resources/countries.json'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 18 },
}

export default {
  components: {
    treeList,
  },
  computed: {
    ...mapState(['info', 'pubfeed']),
    // countriesList: function () {
    //   let countriesList = []
    //   if (typeof this.info.countries !== 'undefined' && this.info.countries.length > 0) {
    //     this.info.countries.forEach(function (el) {
    //       const country = this.adData.Countries.filter(ob => ob.CC === el.CC)
    //       if (typeof country[0] !== 'undefined') {
    //         if (typeof country[0].Enabled === 'undefined') country[0].Enabled = true
    //         countriesList.push(country[0])
    //       } else {
    //         countriesList.push({
    //           Country: el.Country,
    //           CC: el.CC,
    //           BidAdjustment: 100,
    //           CalculatedBid: this.adData.DefaultCPC,
    //           Enabled: false,
    //         })
    //       }
    //     }.bind(this))
    //   }
    //   // Search filter
    //   if (countriesList.length > 0 && this.bidAdjCountryFilter !== '') {
    //     countriesList = countriesList.filter(el => {
    //       return el.Country.toLowerCase().indexOf(this.bidAdjCountryFilter.toLowerCase()) !== -1 ||
    //         el.CC.toLowerCase().indexOf(this.bidAdjCountryFilter.toLowerCase()) !== -1
    //     })
    //   }
    //   // Display Enabled filter
    //   if (countriesList.length > 0 && this.displayEnabledCountries) {
    //     countriesList = countriesList.filter(el => {
    //       return el.Enabled === true
    //     })
    //   }
    //   return countriesList
    // },
    // adData: function() {
    //   let adData = this.$store.getters['ad/getAdData'](this.$route.params.id)
    //   if (typeof adData === 'undefined') {
    //     this.$store.dispatch('ad/LOAD_ADS')
    //     adData = { Countries: [], Browser: [], Os: [] }
    //   }
    //   if (adData.DefaultCPC !== 'undefined' && !isNaN(parseFloat(adData.DefaultCPC))) {
    //     adData.DefaultCPC = Math.round((adData.DefaultCPC + Number.EPSILON) * 1000000) / 1000000
    //   }
    //   return adData
    // },
    enabledCountries: function () {
      if (this.displayEnabledCountries === true) {
        return this.countriesList.filter(el => el.Enabled === true)
      } else if (this.filterSearch !== '') {
        return this.countriesList.filter(el => {
          return el.Country.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1 ||
            el.CC.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1
        })
      }
      return this.countriesList
    },
    DefaultCPC: {
      get: function () {
        return this.adData.DefaultCPC
      },
      set: function (newValue) {
        if (!isNaN(parseFloat(newValue))) this.adData.DefaultCPC = parseFloat(newValue)
        // const countryList = document.querySelectorAll('.countryList input[type=number]')
        // countryList.length > 0 && countryList.forEach(function(el, index) {
        //   this.adjustBid(null, this.countriesList[index], el)
        // }.bind(this))
      },
    },
  },
  data() {
    return {
      countries: staticCountryData,
      countriesList: [],
      selectAllCountries: false,
      filterSearch: '',
      form: this.$form.createForm(this),
      bidAdjCountryColumns: [
        {
          title: 'Country',
          dataIndex: 'Country',
          key: 'Country',
        },
        {
          title: 'Enabled',
          dataIndex: 'Enabled',
          scopedSlots: { customRender: 'Enabled' },
        },
        {
          title: 'CC',
          dataIndex: 'CC',
          key: 'CC',
        },
        {
          title: 'Bid Adjustment',
          dataIndex: 'BidAdjustment',
          scopedSlots: { customRender: 'BidAdjustment' },
        },
        {
          title: 'Calculated Bid',
          dataIndex: 'CalculatedBid',
          scopedSlots: { customRender: 'CalculatedBid' },
        },
      ],
      adData: null,
      bidAdjCountryFilter: '',
      countryPagination: { pageSize: 10 },
      OSData: null,
      BrowserData: null,
      labelCol,
      wrapperCol,
      displayEnabledCountries: false,
    }
  },
  methods: {
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    handleCountries() {
      this.countriesList.forEach(el => {
        el.Enabled = !this.selectAllCountries
      })
    },
    populateCountry: function () {
      if (this.countriesList.length > 0 && this.adData !== null && this.adData.Countries.length > 0) {
        for (const index in this.countriesList) {
          const country = this.adData.Countries.filter(ob => ob.CC === this.countriesList[index].CC)
          if (typeof country[0] !== 'undefined') {
            this.countriesList[index].Enabled = true
          }
        }
      }
    },
    updateDefaultCPC(e) {
      this.DefaultCPC = e.target.value
      this.countriesList.forEach(el => {
        el.CalculatedBid = e.target.value * el.BidAdjustment / 100
        // this.adData.DefaultCPC = el.CalculatedBid
      })
    },
    adjustBid(event, row, obj = null) {
      const object = obj !== null ? obj : event.target
      const newBid = !isNaN(parseFloat(object.value)) ? parseFloat(object.value) : 0
      let itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex === -1) {
        this.countriesList.push({
          Country: row.Country,
          CC: row.CC,
          BidAdjustment: row.BidAdjustment,
          CalculatedBid: (this.adData.DefaultCPC * row.BidAdjustment / 100),
          Enabled: false,
        })
      }
      itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex !== -1) {
        this.countriesList[itemIndex].BidAdjustment = newBid
        this.countriesList[itemIndex].CalculatedBid = newBid === 0 ? 0 : this.adData.DefaultCPC * newBid / 100
      }
    },
    enableCountry(event, row) {
      const itemIndex = this.adData.Countries.findIndex(el => el.CC === row.CC)
      if (itemIndex === -1) {
        this.adData.Countries.push({
          Country: row.Country,
          CC: row.CC,
          BidAdjustment: row.BidAdjustment,
          CalculatedBid: (this.adData.DefaultCPC * row.BidAdjustment / 100),
          Enabled: true,
        })
      } else {
        this.adData.Countries[itemIndex].Enabled = event.target.checked
      }
      // Recalculate bid
      const li = this.$_getContainer(event.target)
      const input = li.querySelector('input[type="number"]')
      this.adjustBid(null, row, input)
    },
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      const countries = []
      this.countriesList.forEach(el => {
        if (el.Enabled) {
          countries.push({
            Country: el.Country,
            CC: el.CC,
            BidAdjustment: el.BidAdjustment,
            CalculatedBid: el.CalculatedBid,
          })
        }
      })
      const oss = this.$_formatTreeData('os')
      const browsers = this.$_formatTreeData('browser')

      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.destinationURL !== '' && values.destinationURL) {
            values.destinationURL = values.destinationURL.replace(/\s/g, '')
          }
          this.$store.dispatch('ad/UPDATE_AD', {
            id: this.adData.Id,
            payload: {
              ...values,
              DefaultCPC: this.DefaultCPC,
              Browsers: browsers,
              Os: oss,
              Countries: countries,
            },
            callback: function () {
              this.$router.push({ name: 'xml-ads' })
            }.bind(this),
            // @click="$router.push('/ads')"
          })
        }
      })
    },
    $_formatTreeData(type = 'os') {
      const searchData = type === 'os' ? this.OSData : this.BrowserData
      const formatedData = []
      searchData.forEach(el => {
        // Add if all versions selected
        if (el.enabled) {
          const temp = {
            Family: el.Family,
            BidAdjustment: el.BidAdjustment,
          }
          if (el.Versions.length > 0) {
            const tmpV = []
            el.Versions.forEach(ss => {
              if (ss.enabled) {
                const temp2 = {
                  BidAdjustment: ss.BidAdjustment,
                  Major: ss.Major,
                }
                if (typeof ss.Minor !== 'undefined') temp2.Minor = ss.Minor
                tmpV.push(temp2)
              }
            })
            temp.Versions = tmpV
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') temp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(temp)
          return
        }
        // Filter versions
        const versions = []
        el.Versions.length > 0 && el.Versions.forEach(ver => {
          if (ver.enabled) {
            const temp = {
              BidAdjustment: ver.BidAdjustment,
              Major: ver.Major,
            }
            if (typeof ver.Minor !== 'undefined') temp.Minor = ver.Minor
            versions.push(temp)
          }
        })
        // Add if one or more versions is used
        if (versions.length > 0) {
          const tmp = {
            Family: el.Family,
            BidAdjustment: el.BidAdjustment,
            Versions: versions,
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') tmp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(tmp)
        }
      })
      return formatedData
    },
    $_getContainer(el, tag = 'LI') {
      return el.tagName === tag ? el : this.$_getContainer(el.parentElement, tag)
    },
    defaultCpcValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Default CPC can not be negative.')
      }
      message()
    },
    toggleAdStatus() {
      const status = this.adData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Remote Video Zone status?',
        content: h => <div>{status} Remote Video Zone <strong></strong>?</div>,
        onOk: function () {
          const newStatus = this.adData.Status === 0 ? 1 : 0
          changeAdStatus({
            id: this.adData.Id,
            status: newStatus,
          }).then((response) => {
            this.adData.Status = response.Ad.Status
            Vue.prototype.$notification.success({
              message: (response.Ad.Status === 2 ? 'Remote Video Zone Deleted' : 'Status Changed'),
              description: (response.Ad.Status === 2 ? 'Remote Video Zone successfully deleted.' : 'Remote Video Zone status successfully changed.'),
            })
          })
        }.bind(this),
      })
    },
  },
  created() {
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('info/LOAD_OSS')
    this.$store.dispatch('info/LOAD_BROWSERS')
    this.$store.dispatch('pubfeed/LOAD_PUBFEEDS')
    getAds(this.$route.params.id).then((response) => {
      this.adData = response
      this.populateCountry()
    })
      .then(() => {
        this.$store.dispatch('info/LOAD_COUNTRIES').then((res) => {
          res.forEach((el, index) => {
            const country = this.adData.Countries.filter(ob => ob.CC === el.CC)
            if (typeof country[0] !== 'undefined') {
              if (typeof country[0].Enabled === 'undefined') country[0].Enabled = true
              this.countriesList.push(country[0])
              return false
            }
            this.countriesList.push({
              Country: el.Country,
              CC: el.CC,
              Enabled: false,
              BidAdjustment: 100,
              CalculatedBid: this.adData.DefaultCPC,
            })
          })
          // this.populateCountry()
        })
      })
  },
}
</script>
<style lang="css" scoped>
.valuePadding {
  background-color: #ffffff;
  border-radius: 4px;
  padding-right: 5px;
  border: #e9edf3 1px solid;
}

.valuePadding input {
  border: none;
  background-color: transparent;
  margin-top: 0;
}

.valuePadding .bid-adjustment {
  padding-bottom: 0px;
  padding-top: 0px;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.custom-table th,
.custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line>>>li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
